import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    foodicsID: Yup.string().required(),
    accountCode: Yup.string().required(),
    type: Yup.number().required()
});

const hasDataInThirdpartyAPI = async () => {
    try {
      const companyName = localStorage.getItem("CompanyName");
      const thirdPartyURL = `https://${companyName}.api-dev.sandbox-portal.ioi-tech.com/api/IOIMiddleWare/Thirdparty`;
   
      const response = await fetch(thirdPartyURL);
      const data = await response.json();
   
     // Check if the API call was successful and data is present
        if (data && data.length > 0) {
       // Use dynamic URL with CompanyName
       formData[0].optionsURI = `https://${companyName}.api-dev.sandbox-portal.ioi-tech.com/api/IOIMiddleWare/PaymentMethods`;
     }
     } catch (error) {
    // Handle error from API call
       console.log(error);
       // Set formData to fallback values if the Thirdparty API call returns an error
       formData[0].type = "text";
     }
    };
   
    hasDataInThirdpartyAPI();

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        foodicsID: "",
        accountCode: "",
        type: 0
    },
};
const FoodicsID = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: `${element.name}_#_${element.id}`
        })
    });
    return Options;
    
}

const ChartOfAccount = (data) => {
    const Options = []
    data=data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.code}-${element.name}`,
            id: `${element.name}_#_${element.code}`
        })
    });
    return Options;
}
const PaymentType = [
    { id: 0, name: "Cash" },
    { id: 1, name: "BankTransfer" },
]

export const formData = [
    { name: "foodicsID", width: "48%", company: true, type: "dropdown", optionsURI: "/IOIMiddleWare/PaymentMethods", parseData: FoodicsID , editDisabled:true },
    { name: "accountCode", width: "48%", company: true, type: "dropdown", optionsURI: "Financials/ChartOfAccount/Short", parseData: ChartOfAccount },
    { name: "type", type: "dropdown", options: PaymentType, width: "48%", },
];
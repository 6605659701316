import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    client_id: Yup.string().trim().required(),
    client_secret: Yup.string().trim().required(),
    redirect_uri: Yup.string().trim().required(),
    token_request_uri: Yup.string().trim().required(),
    authorization_uri: Yup.string().trim().required(),
    state: Yup.string().trim().required(),
    api_uri: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        client_id: "",
        client_secret: "",
        redirect_uri: "",
        token_request_uri: "",
        authorization_uri: "",
        state: "",
        api_uri: "",
    },
};


export const formData = [
    { name: "client_id", type: "text" },
    { name: "client_secret", type: "text" },
    { name: "redirect_uri", type: "text" },
    { name: "token_request_uri", type: "text" },
    { name: "authorization_uri", type: "text" },
    { name: "state", type: "text" },
    { name: "api_uri", type: "text" },
];
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  foodicsID: Yup.string().required(),
  sapCode: Yup.string().trim().required(),
  sapCostCenterCode: Yup.string().trim().required(),
  sapOneTimeCustomerCode: Yup.string().required(),
  sapWarehouseCode: Yup.string().required(),
});

 const hasDataInThirdpartyAPI = async () => {
   try {
     const companyName = localStorage.getItem("CompanyName");
   const thirdPartyURL = `https://${companyName}.api-dev.sandbox-portal.ioi-tech.com/api/IOIMiddleWare/Thirdparty`;
  
      const response = await fetch(thirdPartyURL);
     const data = await response.json();
  
    // Check if the API call was successful and data is present
       if (data && data.length > 0) {
      // Use dynamic URL with CompanyName
      formData[0].optionsURI = `https://${companyName}.api-dev.sandbox-portal.ioi-tech.com/api/IOIMiddleWare/Branches`;
    }
    } catch (error) {
   // Handle error from API call
    console.log(error);
      // Set formData to fallback values if the Thirdparty API call returns an error
      formData[0].type = "text";
    }
   };
  
   hasDataInThirdpartyAPI();

export const formikProps = {
  validationSchema,
  formik: {
    onChangeValidate: false,
    onBlurValidate: false,
  },
  initialVals: {
    foodicsID: "",
    sapCode: "",
    sapCostCenterCode: "",
    sapOneTimeCustomerCode: "",
    sapWarehouseCode: "",
  },
};

const costCenterParseData = (data) => {
  const Options = [];
  data = data.value;
  Array.isArray(data) &&
    data.forEach((element) => {
      Options.push({
        name: `${element.centerCode}-${element.centerName}`,
        id: `${element.centerName}_#_${element.centerCode}`,
      });
    });
  return Options;
};
const WarehouseCodeParseData = (data) => {
  const Options = [];
  data = data.value;
  Array.isArray(data) &&
    data.forEach((element) => {
      Options.push({
        name: `${element.warehouseName}`,
        id: `${element.warehouseName}_#_${element.warehouseCode}`,
      });
    });
  return Options;
};
const FoodicsID = (data) => {
  const Options = [];
  Array.isArray(data) &&
    data.forEach((element) => {
      Options.push({
        name: `${element.name}`,
        id: `${element.name}_#_${element.id}`,
      });
    });
  return Options;
};

const sapCodeParseData = (data) => {
  const Options = [];
  data = data.value;
  Array.isArray(data) &&
    data.forEach((element) => {
      Options.push({
        name: `${element.name}`,
        id: `${element.name}_#_${element.code}`,
      });
    });
  return Options;
};

export let formData = [
  { name: "foodicsID", width: "48%", company: true, type: "dropdown", optionsURI: "/IOIMiddleWare/Branches", parseData: FoodicsID, editDisabled:true },
  { name: "sapCode", width: "48%", company: true, type: "dropdown", optionsURI: "/HR/Branch", parseData: sapCodeParseData },
  { name: "sapCostCenterCode", width: "48%", type: "dropdown", company: true, optionsURI: "/Financials/ProfitCenters", parseData: costCenterParseData },
  { name: "sapWarehouseCode", width: "48%", type: "dropdown", company: true, optionsURI: "/Inventory/Warehouse/Short", parseData: WarehouseCodeParseData },
  { name: "warehoseOffsetCode", width: "48%", type: "dropdown", company: true, optionsURI: "/Inventory/Warehouse/Short", parseData: WarehouseCodeParseData },
  { name: "sapOneTimeCustomerCode", width: "48%", type: "text" },
];
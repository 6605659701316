import { Button, withStyles } from "@material-ui/core";
import { decorate, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { FormBuilder, ActionButton } from "~/Components/From";
import Loading from "~/Components/Loading";
import { asEntity } from "~/Hoc";
import { NotificationContext } from "~/Services/Providers";
import { formData, formikProps } from "./data";
import useStyles from "./styles";
import { isEmpty } from "~/Services/Helpers";

class ThirdPartySettingsForm extends React.Component {
  static contextType = NotificationContext;
  loading = false; // true , false
  getLoading = false;
  values = {};
  componentDidMount() {
    this.getLoading = true;
    this.props.entityStore.get();
  }

  handleSubmit = (values, editValues = {}) => {
    if (!isEmpty(this.values)) {
      // Handle update submission
      this.loading = true;
      this.props.entityStore.patch(values);
    } else {
      // Handle add submission
      this.loading = true;
      // Perform the API call to post the form values
      this.props.entityStore.post(values);
    }
  };

  entityDidReceived = (data) => {
    if (!!data) {
      let normalizedData = {};
      let DocumentType = data;
      // Removing null values and assigning empty strings
      Object.keys(DocumentType).forEach((key) => {
        if (DocumentType[key] === null) normalizedData[key] = "";
        else normalizedData[key] = DocumentType[key];
      });
      this.values = normalizedData;
    }
    this.getLoading = false;
  };

  entityDidPosted(data) {
    this.successActions("Third Party Settings Added Successfully");
  }
  entityDidUpdated(data) {
    this.successActions("Third Party Settings Updated Successfully");
  }

  successActions = (message) => {
    this.loading = false;
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity: "success",
      message,
    }));
    this.props.setView("show");
  };

  entityDidCatch = (errors) => {
    this.loading = false;
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity: "error",
      message: isEmpty(errors?.title) ? "Something went wrong" : errors.title,
    }));
  };

  render() {
    const { classes } = this.props;

    if (this.getLoading) {
      return <Loading />;
    }

    const hasData = !isEmpty(this.values);

    return (
      <div className={classes.FormWrapper}>
        <FormBuilder
          {...formikProps}
          formData={formData}
          formName="ThirdPartySettings"
          editValues={this.values}
          submitAction={this.handleSubmit}
          customStyle={{ flexBasis: "100%" }}
        >
          <div
            style={{
              flexBasis: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              className={classes.SubmitButton}
              onClick={(e) => this.props.setView("show")}
            >
              Cancel
            </Button>
            {hasData ? (
              <ActionButton
                customClass={classes.SubmitButton}
                title="Update"
                icon="arrow_forward"
                loading={this.loading}
              />
            ) : (
              <ActionButton
                customClass={classes.SubmitButton}
                title="Add"
                icon="arrow_forward"
                loading={this.loading}
              />
            )}
          </div>
        </FormBuilder>
      </div>
    );
  }
}

decorate(ThirdPartySettingsForm, {
  loading: observable,
});

export default withStyles(useStyles)( asEntity({ storeId: "ThirdPartySettings" })(observer(ThirdPartySettingsForm)));

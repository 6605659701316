import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Table from '~/Components/EnhancedTable';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { NotificationContext } from '~/Services/Providers';
import { columns } from './data';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import 'date-fns';
import { ActionButton } from '~/Components/From';


class BrowseFoodicsLog extends React.Component {
  static contextType = NotificationContext;
  tableData = [];
  loading = false;
  per_page = 10;
  page = 0;
  options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  count = 10;
  hasNext = false;
  componentDidMount() {
    this.getData();
  } 


  getData = (page = this.page, rowsPerPage = 10,from = this.from?this.from:new Date().toISOString().slice(0, 10),to = this.to?this.to:new Date().toISOString().slice(0, 10)) => {
    this.getLoading = true
    this.props.entityStore.get({ page, rowsPerPage,from ,to});
    // console.log("this.from",from?from:new Date().toISOString().slice(0, 10),"this.to",to?to:new Date().toISOString().slice(0, 10))
  };
  entityDidReceived(data) {
    if (!!data) {
      this.hasNext = data.hasNext;
      this.tableData = data.values;
      if (data.hasNext) {
        this.count += this.per_page
      }
      else this.count = this.per_page
      this.loading = false;
      this.getLoading = false;
    }
  }

  entityDidCatch(error) {
    this.loading = false;
    this.notify("error", error)
  }
  onDeleteConfirm = (idsArray) => {
    idsArray.map(id => {
      this.props.entityStore.delete({ id });
    });
  }
  entityDidDelete() {
    this.notify("Success", "Row/s was deleted successfully");
  }

  notify = (severity, message) => {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity,
      message
    }))
  }

  ParentTableChange = (action, { page, rowsPerPage, searchText }) => {
    if (this.hasNext) {
      if (
        !this.loading &&
        (action === "changePage" || action === "changeRowsPerPage")
      ) {
        this.getData(page + 1, rowsPerPage);
      }
    }
  };
  render() {

    if (this.loading) {
      return <Loading />
    }
    const options = {
      serverSide: true,
      count: this.count
    };

    return (
      <>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', gap: '50px' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <KeyboardDatePicker
              variant='inline'
              format='yyyy-MM-dd'
              id='date-picker-inline'
              label='From'
              value={this.from}
              onChange={date => { this.from =new Date(date).toISOString().slice(0, 10)}}            
              KeyboardButtonProps={{ 'aria-label': 'change date', }}
              autoOk
            />

            <KeyboardDatePicker
              variant='inline'
              format='yyyy-MM-dd'
              id='date-picker-inline'
              label='To'
              value={this.to}
              autoOk
              onChange={date => { this.to = new Date(date).toISOString().slice(0, 10)}}
              KeyboardButtonProps={{ 'aria-label': 'change date', }}
            />
          </MuiPickersUtilsProvider>

          <ActionButton
            variant="contained"
            icon="call_made"
            title="Get log"
            onClick={this.getData}
          />

        </div>

        {this.getLoading ? <Loading />
          : <Table
            showKeyIndex={0}
            title="FoodicsLog"
            defaultData={this.tableData}
            columns={columns}
            parentDeleteAction={this.onDeleteConfirm}
            ParentTableChange={this.ParentTableChange}
            customOptions={options}
          />}
      </>
    )
  }
}

decorate(BrowseFoodicsLog, {
  tableData: observable,
  loading: observable,
  getLoading: observable,
  to: observable,
  from: observable,
})

export default asEntity({ storeId: 'BrowseFoodicsLog' })(observer(BrowseFoodicsLog));
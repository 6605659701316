import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import ItemDataForm from '../Partials/ItemDataForm';
import ItemFilterDataForm from './../Partials/ItemFilterDataForm/index'
import styles from './styles';
import api from "~/Services/Api";
import { NotificationContext } from '~/Services/Providers';

class ViewItemData extends React.Component {
    static contextType = NotificationContext;

    view = "show";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    ItemsSerialdata = {}
    ProductsSerialdata = {}
    CombosSerialdata = {}
    GiftCardsSerialdata = {}
    ModifierOptionsSerialdata = {}
    ItemsFilterdata = {}

    loading = true;

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.loading = true;
        this.props.entityStore.get({ id: api.Series.seriesTyps.ItemsSerial })
        this.props.entityStore.get({ id: api.Series.seriesTyps.ProductsSerial })
        this.props.entityStore.get({ id: api.Series.seriesTyps.CombosSerial })
        this.props.entityStore.get({ id: api.Series.seriesTyps.GiftCardsSerial })
        this.props.entityStore.get({ id: api.Series.seriesTyps.ModifierOptionsSerial })
        this.props.entityStore.get()
    }
    setView = (view, ID) => {
        this.view = view;
        this.typeID = ID;
        if (view === "show") {
            this.getData()
        }
    }

    entityDidReceived(data) {
        if (!!data) {
            if (data.id == api.Series.seriesTyps.ItemsSerial) {
                this.ItemsSerialdata = data;
            }
            if (data.id == api.Series.seriesTyps.ProductsSerial) {
                this.ProductsSerialdata = data;
            }
            if (data.id == api.Series.seriesTyps.CombosSerial) {
                this.CombosSerialdata = data;
            }
            if (data.id == api.Series.seriesTyps.GiftCardsSerial) {
                this.GiftCardsSerialdata = data;
            }
            if (data.id == api.Series.seriesTyps.ModifierOptionsSerial) {
                this.ModifierOptionsSerialdata = data;
            }

            this.ItemsFilterdata = data;
            this.loading = false;
        }
    }

    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }

    render() {
        const { classes } = this.props;
    
        if (this.loading) {
            return <Loading />;
        }
    
        let formComponent = null;
    
        if (this.view === "edit") {
            if (this.typeID === api.Series.seriesTyps.ItemsFilter) {
                formComponent = (
                    <div className={classes.Edit}>
                        <ItemFilterDataForm
                            {...this.props}
                            setView={(view) => this.setView(view)}
                        />
                    </div>
                );
            } else {
                formComponent = (
                    <div className={classes.Edit}>
                        <ItemDataForm
                            {...this.props}
                            id={this.typeID}
                            setView={(view) => this.setView(view, this.typeID)}
                        />
                    </div>
                );
            }
        }
    
        return (
            <div className={classes.root}>
                {this.view === "show" && (
                    <ShowView
                        title="Item Type Info."
                        data={this.ItemsSerialdata}
                        setView={(view) => this.setView(view, api.Series.seriesTyps.ItemsSerial)}
                        showViewName="ItemsMasterData"
                    />
                )}
                {this.view === "show" && (
                    <ShowView
                        title="Products Type Info."
                        data={this.ProductsSerialdata}
                        setView={(view) => this.setView(view, api.Series.seriesTyps.ProductsSerial)}
                        showViewName="ItemsMasterData"
                    />
                )}
                {this.view === "show" && (
                    <ShowView
                        title="Combos Type Info."
                        data={this.CombosSerialdata}
                        setView={(view) => this.setView(view, api.Series.seriesTyps.CombosSerial)}
                        showViewName="ItemsMasterData"
                    />
                )}
                {this.view === "show" && (
                    <ShowView
                        title="Gift Cards Serial Type Info."
                        data={this.GiftCardsSerialdata}
                        setView={(view) => this.setView(view, api.Series.seriesTyps.GiftCardsSerial)}
                        showViewName="ItemsMasterData"
                    />
                )}
                {this.view === "show" && (
                    <ShowView
                        title="Modifier Options Serial Type Info."
                        data={this.ModifierOptionsSerialdata}
                        setView={(view) => this.setView(view, api.Series.seriesTyps.ModifierOptionsSerial)}
                        showViewName="ItemsMasterData"
                    />
                )}
                {this.view === "show" && (
                    <ShowView
                        title="Item Filter Info."
                        data={this.ItemsFilterdata}
                        setView={this.setView}
                        showViewName="ItemsMasterData"
                    />
                )}
                {formComponent}
            </div>
        );
    } 
}

decorate(ViewItemData, {
    view: observable,
    ItemsSerialdata: observable,
    ProductsSerialdata: observable,
    CombosSerialdata: observable,
    GiftCardsSerialdata: observable,
    ModifierOptionsSerialdata: observable,
})

export default withStyles(styles)(
    asEntity({ storeId: "BrowseSeries" })(
        asEntity({ storeId: "BrowseItemFilter" })(
            observer(ViewItemData)
        )
    )
);

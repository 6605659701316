import React from 'react'
import { Typography, Tooltip, List, IconButton } from '@material-ui/core'
import { Edit } from '@material-ui/icons';
import useStyles from './styles'
import { ShowViewGenerator, TabShowViewGenerator } from "./Partials"
import { isEmpty } from "~/Services/Helpers"


//FIXME fix the name of {Tab} props to follow the camel case convention
function ShowView({ setView, data, title ,Tab, showViewName , shouldValueCheck }) {
    const classes = useStyles();
    
    const showViewProps = {
        showViewName,
        shouldValueCheck,
        data
    }

    return (
        <div className={classes.Show}>
            <div className={classes.ShowHeader}>
                <Typography variant="h6">
                    {title}
                </Typography>
                { setView ? <Tooltip title="Edit">
                    <IconButton aria-label="Edit" onClick={() => setView("edit")}>
                        <Edit />
                    </IconButton>
                </Tooltip> : <></>}
            </div>


            <List className={classes.InfoPanel} >
                {Tab ? <TabShowViewGenerator  {...showViewProps} /> : <ShowViewGenerator {...showViewProps} />}
            </List>
        </div>
    )
}

export default ShowView
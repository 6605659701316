import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import styles from './styles';
import { NotificationContext } from '~/Services/Providers';
import ShowLableWithButton from '~/Components/ShowLableWithButton';
import { syncButtonsData, syncTypes } from './data';

const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);

class ViewSystemInitializationFoodics extends React.Component {
    static contextType = NotificationContext;
    code = parsed.code ? parsed.code : '';
    state = parsed.state ? parsed.state : "";
    view = "show";
    data = {};
    TestConnection = {};
    url = "";
    loading = false;
    TestConnectionloading = false;
    TestConnectionIcon = "wifi";
    getAuthLinkloading = false;
    getAuthLinkIcon = "input_icon";

    syncLoad = [];
    successSync = [];

    componentDidMount() {
        if (this.code) {
            this.props.entityStore.post({ type: "authorize", code: this.code, state: this.state });
        }
    }


    testConnection = () => {
        this.TestConnectionloading = true;
        this.props.entityStore.get({ type: "TestConnection" })
    }
    getAuthLink = () => {
        this.getAuthLinkloading = true;
        this.props.entityStore.get({ type: "getAuthLink" });
    }



    sync = (type, date = "2021/01/01") => {
        this.syncLoad = [...this.syncLoad, type];
        this.props.entityStore.post({ type, dateTime: date });
    }


    getSyncLabel = (type) => {
        return Object.keys(syncTypes).find(key => syncTypes[key] === type)
    }


    entityDidReceived(data) {
        if (!!data) {
            if (data.type == "TestConnection") {
                this.TestConnectionloading = false;
                this.TestConnectionIcon = "wifi";
                this.notify("success", "Connection successfully");
            }
            if (data.type == "getAuthLink") {
                this.getAuthLinkIcon = "input_icon";
                this.url = data.url;
                window.location.replace(this.url);
            }
            if (data.type == "authorize") {
                this.notify("success", "authorize successfully");
            }
        }
    }
    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }

    entityDidCatch({ error, type }) {
        if (type == "TestConnection") {
            this.TestConnectionIcon = "perm_scan_wifi_icon";
            this.TestConnectionloading = false;
            this.notify("error", "Connection error");
        }
        if (type == "getAuthLink") {
            this.getAuthLinkloading = false;
            this.getAuthLinkIcon = "error_outline_icon";
            this.notify("error", error);
        }
        if (Object.values(syncTypes).includes(type)) {
            this.syncLoad = this.syncLoad.filter(syncType => syncType !== type);
            this.successSync = this.successSync.filter(syncType => syncType !== type);
            this.notify("error", error);
        }

    }

    entityDidPosted(data) {
        if (!!data) {
            if (data.type == "authorize") {
                this.notify("error", data);
            }
            if (Object.values(syncTypes).includes(data.type)) {
                this.syncLoad = this.syncLoad.filter(syncType => syncType !== data.type);
                this.successSync = [...this.successSync, data.type];
                const label = this.getSyncLabel(data.type);
                this.notify("success", `${label} successfully`);
            }
        }
    }
    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <>
                {
                    <div className={classes.con}>
                        <ShowLableWithButton
                            label="Auth Link"
                            icon={this.getAuthLinkIcon}
                            handleClick={this.getAuthLink}
                            loading={this.getAuthLinkloading}
                            tooltipTitle="Auth Link"
                        // disabled={!this.getAuthLinkloading}
                        />
                        <ShowLableWithButton
                            label="Is Connected"
                            icon={this.TestConnectionIcon}
                            handleClick={this.testConnection}
                            loading={this.TestConnectionloading}
                            tooltipTitle="Test Connection"
                        />
                        {
                            !!syncButtonsData && syncButtonsData.map(syncButton =>
                                <ShowLableWithButton
                                    label={syncButton.label}
                                    tooltipTitle={syncButton.toolTipTitle}
                                    icon={this.successSync.includes(syncTypes[syncButton.label]) ? syncButton.successIcon : syncButton.failIcon}
                                    handleClick={(date) => this.sync(syncTypes[syncButton.label], date)}
                                    loading={this.syncLoad.includes(syncTypes[syncButton.label])}
                                    date={syncButton.withDate}
                                />
                            )
                        }

                    </div>
                }
            </>
        )
    }
}

decorate(ViewSystemInitializationFoodics, {
    view: observable,
})




export default withStyles(styles)(asEntity({ storeId: "ViewSystemInitializationFoodics" })(observer(ViewSystemInitializationFoodics)));


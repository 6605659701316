import { withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import styles from './styles';
import Table from '~/Components/EnhancedTable';
import { columns } from './data';


class ViewFoodicsLog extends React.Component {
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    data = {};
    loading = false;
    tableData = []
    componentDidMount() {
        if (!!this.id) {
            this.loading = true;
            this.props.entityStore.get(this.id)
        }
    }
    entityDidReceived(data) {
        if (!!data) {
            let { id, connectionID, date, startUtc, endUtc, link, logLevel, message, status, statusCode, time, type, } = data;
            // this.data = { id, connectionID, date, startUtc, endUtc, link, logLevel, message, status, statusCode, time, type, }
            this.data = { id,startUtc, endUtc,message, status, type, }
            this.tableData = data.transactions;
            this.loading = false;
        }
    }
    entityDidDelete() {
        this.notify("Success", "Row/s was deleted successfully");
    }
    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }
    onDeleteConfirm = (idsArray) => {
        idsArray.map(id => {
            this.props.entityStore.delete({ id });
        });
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                <ShowView title="Foodics Log Info."
                    data={this.data}
                    showViewName="FoodicsLog"
                />
                <Table
                    showKeyIndex={0}
                    title="FoodicsLog"
                    defaultData={this.tableData}
                    columns={columns}
                    parentDeleteAction={this.onDeleteConfirm}

                />
            </div>
        )
    }
}

decorate(ViewFoodicsLog, {
    data: observable,
    tableData: observable,
})




export default withStyles(styles)(asEntity({ storeId: "FoodicsLogForm" })(observer(ViewFoodicsLog)));

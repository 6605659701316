import { Button, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionButton, FormBuilder } from '~/Components/From';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { foodicsNameDenormalizer, foodicsNameNormalizer, isEmpty } from '~/Services/Helpers';
import { NotificationContext } from '~/Services/Providers';
import {  formData, formikProps } from "./data";
import useStyles from "./styles";

class BranchForm extends React.Component {
    static contextType = NotificationContext;
    loading = false;
    getLoading = false;
    actionType = "Add";
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    values = {};
    fieldsWithName = ['sapCode', 'sapCostCenterCode', 'sapWarehouseCode', 'warehoseOffsetCode', 'foodicsID'];

    componentDidMount() {
        if (!!this.id) {
            this.getLoading = true;
            this.props.entityStore.get(this.id);
        }
    }

    handleSubmit = async (values, editValues = []) => {
        this.loading = true;
        const { values: normalizedValues, editValues: normalizedEditValues } = foodicsNameNormalizer(
          this.fieldsWithName,
          values,
          editValues
        );
      
        const hasDataInThirdpartyAPI = async () => {
          try {
            const companyName = localStorage.getItem("CompanyName");
            const thirdPartyURL = `https://${companyName}.api-dev.sandbox-portal.ioi-tech.com/api/IOIMiddleWare/Thirdparty`;
      
            const response = await fetch(thirdPartyURL);
            const data = await response.json();
            // Check if the API call was successful and data is present
            if (data && typeof data === "object" && Object.keys(data).length > 0) {
              // Use dynamic URL with CompanyName
              formData[0].type = "dropdown"; // Set the field type to dropdown
              formData[0].optionsURI = `https://${companyName}.api-dev.sandbox-portal.ioi-tech.com/api/IOIMiddleWare/Branches`;
              return true;
            }
          } catch (error) {
            // Handle error from API call
            console.log(error);
            // Set formData to fallback values if the Thirdparty API call returns an error
            formData[0].type = "text";
          }
      
          // Set the field type to text
          formData[0].type = "text";
          normalizedValues.foodicsID = normalizedValues.foodicsName; // Set foodicsID to foodicsName
          return false;
        };
      
        const dataExists = await hasDataInThirdpartyAPI();
      
        const payload = {
          ...normalizedValues,
          configured: dataExists ? "true" : "false"
        };
      
        // API call or other actions using the payload
        if (this.actionType !== "Edit") {
          this.props.entityStore.post(payload);
        } else {
          this.props.entityStore.patch({ id: this.id, editValues: normalizedEditValues });
        }
      };
          
      
     entityDidReceived(data) {
        if (!!data) {
            this.getLoading = false;
            let normalizedData = {};
            let BranchData = data;
            //removing null
            Object.keys(BranchData).map(key => {
                if (BranchData[key] === null) normalizedData[key] = '';
                else normalizedData[key] = BranchData[key];
            })
            this.values = foodicsNameDenormalizer(this.fieldsWithName, normalizedData);
        }
    }      

    entityDidPosted(data) {
        this.successActions('Branch Added successfully');
    }

    entityDidUpdated(data) {
        this.successActions('Branch Updated successfully')
    }

    successActions = (message) => {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message
        }
        ))
        this.props.history.goBack()
    }

    entityDidCatch(errors) {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'error',
            message: isEmpty(errors?.title) ? "Something went wrong" : errors.title
        }
        ))
    }


    render() {
        const { classes } = this.props;

        if (this.getLoading) {
            return <Loading />
        }

        return (
            <div className={classes.FormWrapper}>
                <FormBuilder {...formikProps}
                    formData={formData}
                    formName="Branches"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    customStyle={{ flexBasis: "100%" }}
                >
                    <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.SubmitButton}
                            onClick={(e) => !!this.id ? this.props.setView("show") : this.props.history.goBack()}
                        >
                            <FormattedMessage id="Cancel" />
                        </Button>
                        <ActionButton customClass={classes.SubmitButton} title={this.id !== '' ? 'Duplicate' : 'Add'}
                            icon={this.id !== '' ? 'add' : 'arrow_forward'}
                            loading={this.loading}
                        />
                        {
                            this.id !== '' &&
                            <ActionButton customClass={classes.SubmitButton} title="Update" icon="arrow_forward" loading={this.loading}
                                onClick={() => this.actionType = "Edit"}
                            />
                        }
                    </div>
                </FormBuilder>
            </div>

        )
    }
}


decorate(BranchForm, {
    loading: observable,
})

export default withStyles(useStyles)(asEntity({ storeId: "BranchForm" })(observer(BranchForm)));



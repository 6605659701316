import { put, call } from "redux-saga/effects";
import { setStorage } from '~/Services/Helpers';
import get from "lodash/get";
import api from "../../Services/Api";
import { StorageKeys } from "../../Configs/Storage";
import EntityActions from "../Actions/Entity";
import AccountActions from "../Actions/Account";
import { SetRoutData, DefaultNav } from "~/Routes/RouteNavigator";
import { privateRoutesSet } from "~/Routes/RouteList"
import { combineReducers } from "redux";
import { useHistory } from 'react-router-dom'

export default {
  *get({ id, data }) {
    try {
      if (id === "questionControls") {
        const token = localStorage.getItem(StorageKeys.token);
        const res = yield call(
          api.Questions.get,
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          const error = get(res, "data.message.en", " ");
          yield put(EntityActions.getFailed(id, error));
        }
      }
      if (id === "Exhibit") {
        const token = localStorage.getItem(StorageKeys.token);
        const res = yield call(
          api.videos.waiting,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          const error = get(res, "data.message.en", " ");

          yield put(EntityActions.getFailed(id, error));
        }
      }
      if (id === 'BrowseBusinessPartners') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.partners.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'PartnersForm') {       
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.partners.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'CreditMemo') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.creditMemoData.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseDocumentTypes') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.documentTypes.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'DocumentTypesForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.documentTypes.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseContractTypes') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.contractType.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'ContractTypesForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.contractType.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseMaintenanceTypes') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.maintenanceType.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'MaintenanceTypesForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.maintenanceType.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseRepairType') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.repairType.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'RepairTypeForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.repairType.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseTripMaster') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.tripMaster.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'TripMasterForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.tripMaster.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseTypeOfShipment') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.typeOfShipment.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'TypeOfShipmentForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.typeOfShipment.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseShipmentServiceMaster') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.shipmentServiceMasterPlusERPLink.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'ShipmentServiceMasterForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.shipmentServiceMasterPlusERPLink.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseEquipmentType') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.equipmentType.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BranchForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Branch.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'WareHouseForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.WareHouses.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'TaxForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Tax.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'EquipmentTypeForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.equipmentType.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseEquipmentMasterData') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.equipmentMasterData.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseCreditMemoData') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.creditMemoData.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseSalesOrder') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.salesOrder.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseSalesInvoice') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.salesInvoice.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'EquipmentMasterDataForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
           let res;
          if (data.type == "EquipmentMasterData") {
            res = yield call(api.equipmentMasterData.getOne, data.body, { headers: { Authorization: `Bearer ${token}` } });
          } else if (data.type == "equipmentDocument") {
            res = yield call(api.equipmentDocument.getOne, data.body, { headers: { Authorization: `Bearer ${token}` } });
          }
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, { data: res.data, type: data.type }));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseSalesEmployee') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.salesEmployee.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }     
      if (id === 'ViewSalesEmployee') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.salesEmployee.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }      
      if (id === 'BrowseProject') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.project.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'ViewProject') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.project.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseEmployeesMasterData') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.hrEmployee.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'EmployeesMasterDataForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.hrEmployee.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseItItems') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.InventoryItem.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseItFixedAssets') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.InventoryItem.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseItTravel') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.InventoryItem.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseItLabor') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.InventoryItem.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseChartOfAccounts') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.systemInitializationFoodics.chartOfAccount, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'FoodicsSetup') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.FoodicsSetup[data.type], {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, { data: res.data, type: data.type }));
          } else {
            const error = get(res, 'data', ' ');
            yield put(EntityActions.getFailed(id, { error, type: data.type }));
          }
        }
      }
      if (id === 'BrowseSyncPaymentMethods') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.systemInitializationFoodics.syncPaymentMethod, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseAPExpenses') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.APExpenses.get, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseAutoSync' || id === 'UpdateAutoSync') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.systemInitializationAutoSync.get, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
        if (id === 'BrowseSeries' || id === 'BrowseItemFilter'){
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Series.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {     
            yield put(EntityActions.getSucceeded(id, { ...res.data, id: data.id }));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, { error, id: data.id }));
          }
        }
      }

        if (id === 'BrowseItemFilter' || id === 'BrowseSeries') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Series.get, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }

      if (id === 'BrowseCustomerData'){
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Series.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {     
            yield put(EntityActions.getSucceeded(id, { ...res.data, id: data.id }));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, { error, id: data.id }));
          }
        }
      }

      if (id === 'BrowseVendorData'){
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Series.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, { ...res.data, id: data.id }));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, { error, id: data.id }));
          }
        }
      }
      if (id === 'ItemsDataForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.InventoryItem.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'ViewSystemInitializationSAP') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          let res;
          if (data.type == "TestConnection") {
            res = yield call(api.systemInitializationSAP.getTestConnection, {}, { headers: { Authorization: `Bearer ${token}` } });
          } else {
            res = yield call(api.systemInitializationSAP.getDefalutConnection, {}, { headers: { Authorization: `Bearer ${token}` } });
          }
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, { ...res.data, type: data.type }));
          } else {
            const error = get(res, 'data.Message', ' ');
            yield put(EntityActions.getFailed(id, { error, type: data.type }));
          }
        }
      }
      if (id === 'ViewSystemInitializationFoodics') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          let res;
          if (data.type == "TestConnection") {
            res = yield call(api.systemInitializationFoodics.getTestConnection, {}, { headers: { Authorization: `Bearer ${token}` } });
          }
          if (data.type == "getAuthLink") {
            res = yield call(api.systemInitializationFoodics.getAuthLink, {}, { headers: { Authorization: `Bearer ${token}` } });
          }
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, { ...res.data, type: data.type }));
          } else {
            const error = get(res, 'data.Message', ' ');
            yield put(EntityActions.getFailed(id, { error, type: data.type }));
          }
        }
      }
      if (id === "GenericDropdown") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          let res;
          if (data.company) {
            res = yield call(api.GenericHandlers.getCompany, data, { headers: { Authorization: "Bearer " + token, }, });
          } else {
            res = yield call(api.GenericHandlers.get, data, { headers: { Authorization: "Bearer " + token, }, });
          }
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, { payload: res.data, uri: data.uri, code: data.code }));
          } else {
            const error = get(res, "data.message.en", " ");
            yield put(EntityActions.getFailed(id, { ...error, uri: data.uri, code: data.code }));
          }

        }
      }
      if (id === 'BrowseBranch') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Branch.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            console.log("Branch",id,res.data)
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseWareHouse') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.WareHouses.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseCategory') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Category.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseCharge') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Charge.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseTax') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Tax.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseAPServiceContract') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.APServiceContract.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'APServiceContractForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.APServiceContract.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseARServiceContract') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.ARServiceContract.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'ARServiceContractForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.ARServiceContract.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'APMaintenanceTransaction') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.APMaintenanceTransaction.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'APMaintenanceTransactionForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.APMaintenanceTransaction.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'GIMaintenanceTransaction') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.GIMaintenanceTransaction.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'GIMaintenanceTransactionForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.GIMaintenanceTransaction.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowsePaymentMethods') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.PaymentMethod.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'PaymentMethodForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.PaymentMethod.getOne, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === "CategoryForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Category.getOne, data, { headers: { Authorization: "Bearer " + token, }, });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, res.data));
          }
        }
      }
      if (id === "ChargeForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.Charge.getOne, data, { headers: { Authorization: "Bearer " + token, }, });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, res.data));
          }
        }
      }
      if (id === 'BrowseVanSales') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.VanSales.getAll, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            console.log("Van",id,res.data)
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === 'BrowseFoodicsLog') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.foodicsLog.getAll, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
      if (id === "FoodicsLogForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.foodicsLog.getOne, data, { headers: { Authorization: "Bearer " + token, }, });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            yield put(EntityActions.getFailed(id, res.data));
          }
        }
      }
      if (id === "ThirdPartySettings") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.ThirdPartySettings.get, {}, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.getSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.getFailed(id, error));
          }
        }
      }
    } catch (errors) {
      console.log("errors", errors);
      yield put(EntityActions.postFailed(id, "errorConnection"));
    }
  },
  *post({ id, data }) {
    try {
      if (id === "login") {
        const res = yield call(api.account.login, data);
        if (res.ok) {
          const { token } = res.data;
          yield put(AccountActions.setToken(token));
          yield put(EntityActions.postSucceeded(id, res.data));
          
        } else {
          // const error = get(res, "data.message", " ");
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
      if (id === "register") {
        const res = yield call(api.Users.addUser, data);
        if (res.ok) {
          console.log("success")
          yield put(EntityActions.postSucceeded(id, res.data));
          if(res.data){
            console.log("i'm hereeeeeee")
          }
        } else {
          console.log("error")
          const error = get(res, "data", []);
          yield put(EntityActions.postFailed(id, error));
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
      if (id === "MenuAndPermissions") {
        const token = localStorage.getItem(StorageKeys.token);
        const param1 = localStorage.getItem(StorageKeys.Company).split(".")[0];
        const res = yield call(api.Users.getUserPermissions, { data: "" }, { headers: { Authorization: "Bearer " + token }, });
        const res2 = yield call(api.Menu.get, {}, { headers: { Authorization: "Bearer " + token } }, param1);
        if (res.ok && res2.ok) {
          let company = data.company;
          let companyList = !!res.data?.comLists ? res.data?.comLists : {};
          let Permissions = []
          for (let i = 0; i < companyList.length; i++) {
            if (companyList[i].companyID == company)
              Permissions = [...companyList[i].permissions, ...Permissions]
          }
          setStorage(StorageKeys.permissions, JSON.stringify(Permissions));
          SetRoutData(Permissions, { ...DefaultNav, ...res2.data })
          privateRoutesSet();
          yield put(EntityActions.postSucceeded(id, { ...res.data, ...res2.data }));
          
        } else {
          const error = get(res, "data.message", " ");
          yield put(EntityActions.postFailed(id, error));
        }
      }
      if (id === "questionControls") {
        const token = localStorage.getItem(StorageKeys.token);
        const res = yield call(api.Questions.getAns, data, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          const error = get(res, "data.message", " ");
          yield put(EntityActions.postFailed(id, error));
        }
      }
      if (id === "DocumentTypesForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.documentTypes.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "ContractTypesForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.contractType.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "EquipmentDocumentForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.equipmentDocument.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "EquipmentTypeForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.equipmentType.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "EquipmentMasterDataForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.equipmentMasterData.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "MaintenanceTypesForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.maintenanceType.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "GIMaintenanceTransactionForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.GIMaintenanceTransaction.add, data, { headers: { Authorization: "Bearer " + token, }, });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "RepairTypeForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.repairType.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "TripMasterForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.tripMaster.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "TypeOfShipmentForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.typeOfShipment.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "ShipmentServiceMasterForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.shipmentServiceMasterPlusERPLink.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "ViewSystemInitializationFoodics") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          
          let res = yield call(api.systemInitializationFoodics[data.type], data, { headers: { Authorization: "Bearer " + token, }, });

          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, { ...res, type: data.type }));
          } else {
            const error = get(res, 'data.Message', ' ');
            yield put(EntityActions.postFailed(id, { error, type: data.type }));
          }
        }
      }
      if (id === "ViewSystemInitializationSAP") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.systemInitializationSAP.addDefalutConnection,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }

      if (id === 'UpdateAutoSync' || id === 'BrowseAutoSync') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.systemInitializationAutoSync.post,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "SetSeries") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.Series.SetSeries,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "SetItemFilter") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.Series.SetItem,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "ProfileFrom") {
        const token = localStorage.getItem(StorageKeys.token);
        const user = JSON.parse(localStorage.getItem(StorageKeys.userContext));
        const res = yield call(api.account.UpdateUserInfo, { email: user.data.email, data: data }, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          const error = get(res, "data.message", " ");
          yield put(EntityActions.postFailed(id, error));
        }
      }
      if (id === "BranchForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.Branch.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "WareHouseForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.WareHouses.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "APExpenseForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.APExpenses.add, data, { headers: { Authorization: "Bearer " + token, }, });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "WareHouseForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.WareHouses.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "CategoryForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.Category.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "ChargeForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.Charge.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "TaxForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.Tax.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "APServiceContractForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.APServiceContract.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "APMaintenanceTransactionForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.APMaintenanceTransaction.add, data, { headers: { Authorization: "Bearer " + token, }, });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === "ARServiceContractForm") {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(
            api.ARServiceContract.add,
            data,
            {
              headers: {
                Authorization: "Bearer " + token,
              },
            }
          );
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            yield put(EntityActions.postFailed(id, res.data));
          }
        }
      }
      if (id === 'PaymentMethodForm') {
        const token = localStorage.getItem(StorageKeys.token);
        if (token) {
          const res = yield call(api.PaymentMethod.add, data, { headers: { Authorization: `Bearer ${token}` } });
          if (res.ok) {
            yield put(EntityActions.postSucceeded(id, res.data));
          } else {
            const error = get(res, 'data.message', ' ');
            yield put(EntityActions.postFailed(id, error));
          }
        }
      }
    if (id === "ThirdPartySettings") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.ThirdPartySettings.post,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          yield put(EntityActions.postFailed(id, res.data));
        }
      }
    }
    } catch (errors) {
      console.log("errors", errors);
      yield put(EntityActions.postFailed(id, "errorConnection"));
    }
  },
  * put({ id, data }) {
    if (id === "PasswordForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.account.resetPassword,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
  },
  * patch({ id, data }) {
    if (id === "EquipmentDocumentForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.equipmentDocument.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "DocumentTypesForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.documentTypes.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "ContractTypesForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.contractType.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "GIMaintenanceTransactionForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.GIMaintenanceTransaction.update, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "APMaintenanceTransactionForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.APMaintenanceTransaction.update, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "BranchForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.Branch.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "WareHouseForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.WareHouses.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "TaxForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.Tax.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "CategoryForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.Category.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "ChargeForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.Charge.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "PaymentMethodForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.PaymentMethod.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "EquipmentTypeForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.equipmentType.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "MaintenanceTypesForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.maintenanceType.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "RepairTypeForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.repairType.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "TripMasterForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.tripMaster.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "TypeOfShipmentForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.typeOfShipment.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "ShipmentServiceMasterForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.shipmentServiceMasterPlusERPLink.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
     if (id === "EquipmentMasterDataForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.equipmentMasterData.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
    if (id === "ThirdPartySettings") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(
          api.ThirdPartySettings.update,
          data,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (res.ok) {
          yield put(EntityActions.putSucceeded(id, res.data));
        } else {
          yield put(EntityActions.putFailed(id, res.data));
        }
      }
    }
  },
  * delete({ id, data }) {
    if (id === "EquipmentMasterDataForm") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.equipmentDocument.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseDocumentTypes") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.documentTypes.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseContractTypes") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.contractType.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseMaintenanceTypes") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.maintenanceType.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseRepairType") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.repairType.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseShipmentServiceMaster") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.shipmentServiceMasterPlusERPLink.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseTripMaster") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.tripMaster.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseTypeOfShipment") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.typeOfShipment.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseEquipmentType") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.equipmentType.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseEquipmentMasterData") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.equipmentMasterData.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseAPServiceContract") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.APServiceContract.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseARServiceContract") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.ARServiceContract.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseBranch") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.Branch.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseWareHouse") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.WareHouses.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if (id === "BrowseTax") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.Tax.delete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          const error = get(res, 'data.message', 'Connection Error');
          yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
    if(id === "APMaintenanceTransaction") {
      const token = localStorage.getItem(StorageKeys.token);
      if (token) {
        const res = yield call(api.APMaintenanceTransaction.softDelete, data, { headers: { Authorization: "Bearer " + token, }, });
        if (res.ok) {
          // yield put(EntityActions.deleteSucceeded(id, {}));
        } else {
          // const error = get(res, 'data.message', 'Connection Error');
          // yield put(EntityActions.deleteFailed(id, error));
        }
      }
    }
  },
};

import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { Button, Chip } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { AddOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import CustomToolbar from './Partials/Toolbar';
import { getStorage, setStorage } from '~/Services/Helpers';

function EnhancedTable({ handleOpen, addLink, addToUrl = "", showKeyIndex, addButtonText, title, defaultData, customOptions, columns, parentDeleteAction, ParentTableChange }) {
  const classes = useStyles();
  const history = useHistory();
  const [tableData, setTableData] = useState( defaultData?  defaultData.value ? defaultData.value:defaultData:defaultData );

  useEffect(() => {
    setTableData(defaultData?  defaultData.value ? defaultData.value:defaultData:defaultData);
  }, [defaultData])

  var columnsOptions = JSON.parse(getStorage("columnsOptions")) || {};


  const translatedColumns = () => columns.map(column => ({
    ...column,
    options: { ...column.options, customHeadLabelRender: () => <FormattedMessage id={`${title}.${column.name}`} /> }
  }
  ))


  const geTableColumns = () => {
    let tableColumns = translatedColumns()
    if (columnsOptions) {
      if (Object.keys(columnsOptions).includes(title)) {
        const viewColumnsArr = columnsOptions[title].split(',');
        for (let i = 0; i < columns.length; i++) {
          tableColumns[i].options = { ...tableColumns[i].options, display: viewColumnsArr[i] === "1" ? true : false };
        }
      }
    }
    return tableColumns
  }

  const deleteAction = (selectedRows, displayData) => {
    let deleteRowsIds = []

    Object.keys(selectedRows.lookup).map(dataIndex => {
      let delRow = displayData.find(row => row.dataIndex === Number(dataIndex));
      deleteRowsIds.push(delRow.data[0]);
    })

    let tempData = tableData.filter(row => !deleteRowsIds.includes(row[Object.keys(row)[0]]))
    setTableData(tempData);

    parentDeleteAction(deleteRowsIds);
  }

  const onTableChange = (tableState) => {
    let arr = [];
    tableState.columns.forEach(value => {
      value.display === "true" ? arr.push(1) : arr.push(0);
    });
    columnsOptions[title] = arr.toString();
    setStorage("columnsOptions", JSON.stringify(columnsOptions));
  }

  const options = {
    filterType: 'dropdown',
    responsive: 'vertical', // Set responsive to "vertical"
    tableBodyHeight: '377.5px', // Adjust the table body height as needed
    tableBodyMaxHeight: 'none', // Set table body max height to "none"
    onRowClick: (row) => handleOpen ? handleOpen("edit", row) : history.push(`${window.location.pathname}${addToUrl}/${row[showKeyIndex]}`),
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) =>
    (<CustomToolbar
      deleteAction={deleteAction}
      selectedRows={selectedRows}
      displayData={displayData}
      setSelectedRows={setSelectedRows}
    />),

    setRowProps: () => ({ style: { cursor: 'pointer' } }),
    onTableChange: (action, tableState) => {
      if (action === "viewColumnsChange") {
        onTableChange(tableState);
      }
      if (!!customOptions?.serverSide) {
        ParentTableChange(action, tableState)
      }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    ...customOptions
  };

  let tableColumns = geTableColumns()
  let addPath = `${window.location.pathname}${addToUrl}/add`
  return (
    <div className={classes.root}>
      { addLink ? <Button
        onClick={() => history.push(addPath)}
        className={classes.AddButton}
        color="secondary"
        variant="contained"
        endIcon={<AddOutlined />}
      >
        <FormattedMessage id="add" />  <FormattedMessage id={addButtonText} />
      </Button> : ""}

      <div className={classes.Table} style={{ marginTop: addLink ? 0 : 40 }}>
        <MUIDataTable
          title={<FormattedMessage id={title} />}
          data={tableData}
          columns={tableColumns}
          options={options}
        />
      </div>
    </div>
  )
}

export default EnhancedTable;
import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';
import { MultipleFileUploadField } from './Partials/MultipleFileUploadField';
import { useNotification } from '~/Services/Providers'
import useStyles from './styles'

export default function Uploader({ accept, aspectRatio, maxFileSize, multiple, InputChange, width, name, label, placeholder, title, editValue, filesLimit,error, errorText }) {
    const { setNotification } = useNotification();
    const classes = useStyles();

    let arr = [];
    let newEditValue = [];

    if (Array.isArray(editValue)) {
        newEditValue = editValue;
    } else if (editValue && editValue != '') {
        arr.push(editValue);
        newEditValue = arr;
    }
    const successActions = (message ,severity ="error") => {
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: severity,
            message
        }
        ))
    }

    return (
        <Card style={{  width }}>
            <CardContent>
            {
                label && <div className={classes.label}>
                {label}
            </div>
            }
                <MultipleFileUploadField
                    name={name}
                    InputChange={InputChange}
                    accept={accept}
                    aspectRatio={aspectRatio}
                    maxFileSize={maxFileSize}
                    multiple={multiple}
                    editValue={newEditValue}
                    notifications={successActions}
                    filesLimit={filesLimit}
                    error={error}
                    errorText={errorText}
                />
            </CardContent>
        </Card>
    );
}

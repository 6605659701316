
export const columns = [
    { name: "docNum"},
    { name: "cardCode"},
    { name: "fatherCard"},
    { name: "docDate"},
    { name: "docTotal"},
    { name: "vatSum"},
];





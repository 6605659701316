import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    startDateTime: Yup.string().trim().required(),
    intervalSecond: Yup.string().trim().required(),
    name: Yup.string().trim().required(),
    // active: Yup.string().trim().required(),
    syncFrmDate: Yup.string().trim().required(),
    lastSussedSyncDT: Yup.string().trim().required(),
    lastStartDT: Yup.string().trim().required(),
    lastEndDT: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        startDateTime: "",
        intervalSecond: "",
        name: "",
        // active: "",
        syncFrmDate: "",
        lastSussedSyncDT: "",
        lastStartDT : "",
        lastEndDT : "",
    },
};

const YesNo = [
    { id: 0, name: "No" },
    { id: 1, name: "Yes" },
]

export const formData = [
    { name: "startDateTime", type: "date", width: "48%", focused: true, },
    { name: "intervalSecond", type: "number", width: "48%", },
    { name: "name", type: "text", width: "48%", },
    // { name: "active", type: "dropdown", options: YesNo, width: "48%", },
    { name: "syncFrmDate", type: "date", focused: true, width: "48%", },
    { name: "lastSussedSyncDT", type: "date", focused: true, width: "48%", },
    { name: "lastStartDT", type: "date", focused: true, width: "48%", },
    { name: "lastEndDT", type: "date", focused: true, width: "48%", },

];
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    SAPItemGroup: Yup.string().trim().required(),

});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        SAPItemGroup: '',
    },
};


const ItemGroups = (data) => {
    const Options = []
    data=data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.groupName}`,
            id: element.number
        })
    });
    
    return Options;
}

export const formData = [
    { name: "SAPItemGroup", type: "dropdown", width: "48%", company: true, optionsURI: "Inventory/ItemGroups/Short", parseData: ItemGroups },
];
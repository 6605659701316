import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string()
    .trim()
    .required("email is a required field")
    .email("please enter a valid email"),
  password: Yup.string()
    .trim()
    .required()
    .matches(/((?=.*[0-9]).{1,})+/, 'Should have 1 digit minimum')
    .matches(/((?=.*[a-z]).{1,})+/, 'Should have 1 character minimum')
    .matches(/((?=.*[a-z])(?=.*[0-9]).{6,8})+/, 'Must be  6 to 8 characters'),
  passwordConfirm: Yup.string()
    .trim()
    .required("password confirm is a required field")
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const formData = [
  {
    name: "name",
    type: "text",
    labelWidth: 80,
    placeholder: "Username",
  },
  {
    name: "email",
    type: "email",
    labelWidth: 40,
    placeholder: "Email",
  },
  {
    name: "phoneNumber",
    type: "phone",
    labelWidth: 40,
    placeholder: "Phone",
  },
  {
    name: "password",
    type: "password",
    labelWidth: 70,
    width: "48%",
    placeholder: "Password",
  },
  {
    name: "passwordConfirm",
    type: "password",
    labelWidth: 140,
    width: "48%",
    placeholder: "Confirm Password",
  },
];
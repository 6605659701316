import { Button, withStyles } from '@material-ui/core';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { ActionButton, FormBuilder } from '~/Components/From';
import Loading from '~/Components/Loading';
import { asEntity } from '~/Hoc';
import { foodicsNameDenormalizer, foodicsNameNormalizer, isEmpty } from '~/Services/Helpers';
import { NotificationContext } from '~/Services/Providers';
import { formData, formikProps } from './data';
import useStyles from "./styles";
import { FormattedMessage } from 'react-intl'

class APMaintenanceTransaction extends React.Component {
    static contextType = NotificationContext;
    loading = false; // true , false
    getLoading = false;
    actionType = "Add";
    values = {};
    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    fieldsWithName = ['cardCode','itemCode', 'projectCode', 'warehouseCode'];
    formfieldsWithName = ['cardCode'];

    componentDidMount() {
        if (!!this.id) {
            this.getLoading = true;
            this.props.entityStore.get(this.id);
        }
    }

    handleSubmit = (values, editValues = []) => {
        this.loading = true;

        const { values: normalizedValues, editValues: normalizedEditValues } = foodicsNameNormalizer(this.formfieldsWithName, values, editValues);

        const normalizedDetails = normalizedValues.maintenanceTrancstionEMT1s.map(entry => {
            let { values } = foodicsNameNormalizer(this.fieldsWithName, entry, []);
            return values
        })
        normalizedValues.maintenanceTrancstionEMT1s = normalizedDetails;

        if (editValues.some(entry => entry.path.includes('maintenanceTrancstionEMT1s'))) {
            normalizedEditValues = editValues.map(entry => {
                if (entry.path.includes('maintenanceTrancstionEMT1s')) {
                    const normalizedDetails = entry.map(subEntry => {
                        let { values } = foodicsNameNormalizer(this.fieldsWithName, subEntry, []);
                        return values
                    })
                    return normalizedDetails
                }
                return entry

            })
        }
        
        if (this.actionType !== "Edit") this.props.entityStore.post(normalizedValues);
        else this.props.entityStore.patch({ id: values.docTypeCode, editValues: normalizedEditValues })

    };


    entityDidReceived(data) {
        if (!!data) {
            this.getLoading = false;
            let normalizedData = {};
            let DocumentType = data;
            //removing null
            Object.keys(DocumentType).map(key => {
                if (DocumentType[key] === null) normalizedData[key] = '';
                else normalizedData[key] = DocumentType[key];
            })
            normalizedData.maintenanceTrancstionEMT1s = normalizedData.maintenanceTrancstionEMT1s.map(entry => {
                let values = foodicsNameDenormalizer(this.fieldsWithName, normalizedData);
                return values
            })
            this.values = normalizedData;
        }
    }

    entityDidPosted(data) {
        this.successActions('AP Maintenance Transaction successfully');
    }

    entityDidUpdated(data) {
        this.successActions('AP Maintenance Transaction updated successfully')
    }

    successActions = (message) => {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'success',
            message
        }
        ))
        this.props.history.goBack()
    }

    entityDidCatch(errors) {
        this.loading = false;
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity: 'error',
            message: isEmpty(errors?.Message) ? "Something went wrong" : errors.Message
        }
        ))
    }
    handleChange = (e , feild) => {
        console.log('Field Value:', e.target.value);
        console.log('Field Name:', feild);
        /*if(feild=="price"){
            let value = this.props.values["price"] * this.props.values["qty"];
            this.props.InputChange("lineTotal", value);
            return value;
        }*/
        this.setState({
            values: { ...this.state.values, feild: e.target.value}
        })
    };

    onChange = (event, setFieldValue, values) => {
        let sub_total = 0;
        let discount = 0;
        let totalItemsPrice = 0;
        switch(event.target.name) {
            case 'qty':
                sub_total = Number(event.target.value || 0) * Number(values['price'] || 0);
                discount = values['discountPercent'] || 0;
                setFieldValue('lineTotal', sub_total - (discount / 100 * sub_total));
                break;
            case 'price':
                sub_total = Number(event.target.value || 0) * Number(values['qty'] || 0);
                discount = values['discountPercent'] || 0;
                setFieldValue('lineTotal', sub_total - (discount / 100 * sub_total));
                break;
            case 'discountPercent':
                sub_total = Number(values['qty'] || 0) * Number(values['price'] || 0);
                discount = Number(event.target.value);
                setFieldValue('lineTotal', sub_total - (discount / 100 * sub_total));
                values['maintenanceTrancstionEMT1s'] && values['maintenanceTrancstionEMT1s'].map((item) => totalItemsPrice += item.lineTotal)
                setFieldValue('total', totalItemsPrice - (discount / 100 * totalItemsPrice));
                break;
            case 'lineTotal':
                sub_total = Number(values['qty'] || 0) * Number(values['price'] || 0);
                const dis = (sub_total - Number(event.target.value || 0)) * (100 / sub_total);
                const roundedDis = Math.round((dis + Number.EPSILON) * 100) / 100;;
                setFieldValue('discountPercent', roundedDis >= 0 ? roundedDis : 0);
                break;
            case 'total':
                values['maintenanceTrancstionEMT1s'] && values['maintenanceTrancstionEMT1s'].map((item) => totalItemsPrice += item.lineTotal)
                const totalDis = (totalItemsPrice - Number(event.target.value || 0)) * (100 / totalItemsPrice);
                const roundedTotalDis = Math.round((totalDis + Number.EPSILON) * 100) / 100;
                setFieldValue('discountPercent', roundedTotalDis >= 0 ? roundedTotalDis : 0);
                break;
            case 'transactions':
                event.target.value && event.target.value.map((item) => totalItemsPrice += item.lineTotal)
                setFieldValue('total', totalItemsPrice - (values['discountPercent'] / 100 * totalItemsPrice));
                break;
            default:

            }
    }

    render() {
        const { classes } = this.props;

        if (this.getLoading) {
            return <Loading />
        }

        return (
            <div className={classes.FormWrapper}>
                <FormBuilder {...formikProps}
                    formData={formData}
                    formName="APMaintenanceTransaction"
                    editValues={this.values}
                    submitAction={this.handleSubmit}
                    handleChange= {this.handleChange}
                    customStyle={{ flexBasis: "100%" }}
                    onChange={this.onChange}
                >
                    <div style={{ flexBasis: "100%", display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.SubmitButton}
                            onClick={(e) => !!this.id ? this.props.setView("show") : this.props.history.goBack()}
                        >
                           <FormattedMessage id="cancel" /> 
                        </Button>
                        <ActionButton customClass={classes.SubmitButton} title={this.id !== '' ? 'Duplicate' : 'Add'}
                            icon={this.id !== '' ? 'add' : 'arrow_forward'}
                            loading={this.loading}
                        />
                        {
                            this.id !== '' &&
                            <ActionButton customClass={classes.SubmitButton} title="Update" icon="arrow_forward" loading={this.loading}
                                onClick={() => this.actionType = "Edit"}
                            />
                        }
                    </div>
                </FormBuilder>
            </div>

        )
    }
}


decorate(APMaintenanceTransaction, {
    loading: observable,
})

export default withStyles(useStyles)(asEntity({ storeId: "APMaintenanceTransactionForm" })(observer(APMaintenanceTransaction)));

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: theme.direction === "ltr" ? 'right' : "left",
        MUIDataTableToolbar: {
            actions: {
                textAlign: "left",
            }
        },
    },
    AddButton: {
        margin: 15,
    },
    Table: {
        textAlign: theme.direction === "ltr" ? 'left' : 'right',
    }
}))
export default useStyles

export const columns = [
    { name: "foodicsID", label: "Foodice Warehouses ID", options: { filter: true, sort: true, } },
    { name: "foodicsName", label: "Foodice Warehouses ID", options: { filter: true, sort: true, } },
    { name: "sapCode", label: "Code SAP", options: { filter: true, sort: true, } },
    { name: "sapName", label: "Code SAP", options: { filter: true, sort: true, } },
    { name: "warehoseOffsetCode", label: "Whose offset", options: { filter: true, sort: true, } },
    { name: "warehoseOffsetName", label: "Whose offset", options: { filter: true, sort: true, } },
    { name: "sapCostCenterCode", label: "cost Center", options: { filter: true, sort: true, } },
    { name: "sapCostCenterName", label: "cost Center", options: { filter: true, sort: true, } },
    { name: "salesPersonCode", label: "Sales Person Code", options: { filter: true, sort: true, } },
    { name: "salesPersonName", label: "Sales Person Name", options: { filter: true, sort: true, } },
];
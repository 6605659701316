import React, { useState } from 'react';
import useStyles from './styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from './TabPanel';

function Bar({ AppBarLabels, RenderedComponents, variant, activeTab, setActiveTab, ...rest }) {
  const [value, setValue] = useState(activeTab || 0);
  const classes = useStyles();

  const getValue = () => {
    return activeTab != null ? activeTab : value;
  };

  const setTab = (index) => {
    if (setActiveTab) {
      setActiveTab(index);
    } else {
      setValue(index);
    }
  };

  return (
    <div className={classes.appBar}>
      <AppBar position='static' color="primary" >
        <Tabs
          value={getValue()}
          onChange={(event, value) => setTab(value)}
          indicatorColor="secondary"
          variant={variant}
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          centered
        >
          {AppBarLabels.map((item, index) => {
            // Only render the tab if the corresponding component is available in RenderedComponents
            if (RenderedComponents[index]) {
              return <Tab key={index} value={index} label={item.label} {...TabProps('' + index)} />;
            }
            return null;
          })}
        </Tabs>
      </AppBar>

      {RenderedComponents.map((Component, index) => (
        // Only render the component if it is available in RenderedComponents
        Component ? (
          <TabPanel key={index} value={getValue()} index={index}>
            {Component(rest)}
          </TabPanel>
        ) : null
      ))}
    </div>
  );
}

Bar.defaultProps = {
  activeTab: null,
  setActiveTab: null,
  variant: 'scrollable',
};

function TabProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default Bar;

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    active: {
        backgroundColor: '#8ECBF0',},
    Icon: {
      fontSize: 17,
      color: '#fff',
    },
    text: {
      fontSize: 17,
      color: '#fff',
    },
    // MenuItem: {
    //   textAlign: theme.direction === 'ltr' ? 'left' : 'right',
    //   '&$active': {
    //     backgroundColor: '#8ECBF0',
    //     color: '#fff',
    //   },
    // },
  }));
  
  export const Styles = {
    padding: (level = 0) => ({
      padding: `${level * 8}px`,
    }),
  };
  
  export default useStyles;
  
import { Button, CircularProgress, withStyles } from "@material-ui/core";
import { decorate, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Input, PhoneNumInput } from "~/Components/From";
import { asEntity, withFormik } from "~/Hoc";
import { NotificationContext } from "~/Services/Providers";
import { formData } from "./data";
import useStyles from "./styles";

class RegisterForm extends React.Component {
  static contextType = NotificationContext;

  loading = false; // true , false
  errors = {};

  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
    };
  }

  handlePhoneNumChange = (name, phoneNumber) => {
    this.setState({ phoneNumber });
  };
  
  
  handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Validate the form
      await this.props.validateForm();
  
      if (this.props.isValid) {
        const { phoneNumber } = this.state;
        const payload = {
          ...this.props.values,
          phoneNumber: phoneNumber,
        };
  
        // Make the API call using the modified payload
        await this.props.entityStore.post(payload);
  
        // Clear form fields
        this.formRef.current.reset();
        this.setState({ phoneNumber: '+966' });
        this.notify("success", "Registered successfully");
      }
    } catch (error) {
      this.notify("error", error);
    }
  };
  

  entityDidPosted(data) {
    if (data) {
      this.loading = false;
    }
  }

  entityDidCatch(error) {
    this.loading = false;
    this.notify("error", error);
  }

  notify = (severity, message) => {
    const { setNotification } = this.context;
    setNotification((currState) => ({
      ...currState,
      open: true,
      severity,
      message,
    }));
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <form ref={this.formRef} noValidate onSubmit={this.handleSubmit}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {formData.map((field) =>
              field.type === "phone" ? (
                <PhoneNumInput
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  value={this.state.phoneNumber}
                  InputChange={this.handlePhoneNumChange}
                />
              ) : (
                <Input
                  type={field.type}
                  name={field.name}
                  labelWidth={field.labelWidth}
                  placeholder={
                    <FormattedMessage id={`Auth.RegisterForm.${field.name}`} />
                  }
                  icon={field.icon}
                  InputChange={this.props.handleChange}
                  id={`register-form-${field.name}`}
                  key={`reigster-form-${field.name}`}
                  error={this.props.errors[field.name]}
                  errorText={this.props.errors[field.name]}
                />
              )
            )}

            <div style={{ flexBasis: "100%", textAlign: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.SubmitButton}
                endIcon={
                  this.loading ? (
                    <CircularProgress color="primary" size={15} />
                  ) : null
                }
                type="submit"
                disabled={this.loading}
              >
                <FormattedMessage id="Auth.Register" />
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

decorate(RegisterForm, {
  loading: observable,
  errors: observable,
});

export default withStyles(useStyles)(
  withFormik(asEntity({ storeId: "register" })(observer(RegisterForm)))
);
import { EmptyPage } from "~/Components/ErrorPages";
import ViewSystemInitializationFoodics from "~/Screens/Administration/SystemInitialization/General-Settings-Foodics/Edit-View";
import HomePage from "~/Screens/Administration/HomePage";

/*  getAllPath: loop through all children and returns the rout paths 
    params:
        data <Object> : object have children, path and slug
    returns:
        <Array> :  array of object have all the paths in given data and slugs
 */
export function getAllPath(data) {
    let paths = []
    if (data.children && Array.isArray(data.children)) {
        for (let i = 0; i < data.children.length; i++) {
            let path = getAllPath(data.children[i])
            if (path) {
                if (Array.isArray(path)) {
                    paths = [...paths, ...path]
                }
                else paths = [...paths, path]
            }
        }
        // console.log("Path",paths)
        return paths
    }
    else {
        return { path: data.path, slug: data.Slug }
    }

}

/*  getPrivateRoutes: loop through navData get the paths and slugs in the menu and matches with     
    components slugs
    params:
        navData <Object> : menu object
        data <Object> : routList data of app component
    returns:
        <Array> :  array of object have all the paths in given data and slugs
 */
export function getPrivateRoutes(navData, data) {

    if (Object.keys(navData).length === 0) { return false }
    let pathSlugObj = []
    let PrivateRoutes = []

    Object.keys(navData).map((key) => {
        const oneKeyPaths = getAllPath(navData[key])
        if (Array.isArray(oneKeyPaths)) pathSlugObj = [...pathSlugObj, ...oneKeyPaths]
        else pathSlugObj = [...pathSlugObj, oneKeyPaths]
    })

    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < pathSlugObj.length; j++) {
            if (pathSlugObj[j].slug === data[i].Slug) {
                if (data[i].component) PrivateRoutes.push({ path: pathSlugObj[j].path, component: data[i].component, exact: true })
                else {
                    let components = data[i].components
                    let path = !data[i].addToPath ? pathSlugObj[j].path : pathSlugObj[j].path + data[i].addToPath;
                    PrivateRoutes.push({ path: path + "/add", component: components[0], exact: true })
                    PrivateRoutes.push({ path: path + "/:id", component: components[1], exact: true })
                    if (components.length === 3) PrivateRoutes.push({ path: path, component: components[2], exact: true })
                }
            }
        }
    }

    return [
        ...PrivateRoutes,
        { path: "/home-page", component: HomePage },
        { path: "/foodics", component: ViewSystemInitializationFoodics },
        { path: "/*", component: EmptyPage, exact: true }
    ]
}
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    sapCode: Yup.string().trim().required(),
    foodicsID: Yup.string().trim().required(),
});

export const formikProps = {
    validationSchema,
    formik: {
        onChangeValidate: false,
        onBlurValidate: false,
    },
    initialVals: {
        sapCode: '',
        foodicsID: '',
    },
};

const hasDataInThirdpartyAPI = async () => {
    try {
      const companyName = localStorage.getItem("CompanyName");
      const thirdPartyURL = `https://${companyName}.api-dev.sandbox-portal.ioi-tech.com/api/IOIMiddleWare/Thirdparty`;
   
      const response = await fetch(thirdPartyURL);
      const data = await response.json();
   
     // Check if the API call was successful and data is present
        if (data && data.length > 0) {
       // Use dynamic URL with CompanyName
       formData[0].optionsURI = `https://${companyName}.api-dev.sandbox-portal.ioi-tech.com/api/IOIMiddleWare/charges`;
     }
     } catch (error) {
    // Handle error from API call
     console.log(error);
       // Set formData to fallback values if the Thirdparty API call returns an error
       formData[0].type = "text";
     }
    };
   
    hasDataInThirdpartyAPI();
    
const FoodicsID = (data) => {
    const Options = []
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id: `${element.name}_#_${element.id}`
        })
    });
    return Options;
}
const apExpenseCode = (data) => {
    const Options = []
    data=data.value;
    Array.isArray(data) && data.forEach(element => {
        Options.push({
            name: `${element.name}`,
            id:  `${element.name}_#_${element.expensCode}`
        })
    });
    return Options;
}
export const formData = [
    { name: "foodicsID", company: true, type: "dropdown", optionsURI: "/IOIMiddleWare/charges", parseData: FoodicsID , editDisabled:true },
    { name: "sapCode", type: "dropdown", company: true, optionsURI: "Financials/AdditionalExpenses/Short", parseData: apExpenseCode },
];

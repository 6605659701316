import React from "react";
import Branch from "../Partials/Branch/Browse";
import WareHouse from "../Partials/WareHouse/Browse";
import ItemData from "../Partials/ItemData/Edit-View";
import Category from "../Partials/Category/Browse";
import Charge from "../Partials/Charges/Browse";
import Customer from "../Partials/Customer/Edit-View";
import APExpense from "../Partials/APExpense/Edit-View";
import Vendor from "../Partials/Vendor/Edit-View";
import Tax from "../Partials/Tax/Browse";
import PaymentMethods from "../Partials/PaymentMethods/Browse";
import VanSale from "../Partials/VanSale/Browse";

const userEmailAddress = localStorage.getItem("emailID");

const AppBarComponents = [
  { key: "branch", component: (props) => <Branch {...props}></Branch> },
  {
    key: "wareHouse",
    component: (props) => <WareHouse {...props}></WareHouse>,
  },
  { key: "customer", component: (props) => <Customer {...props}></Customer> },
  { key: "vendor", component: (props) => <Vendor {...props}></Vendor> },
  { key: "itemData", component: (props) => <ItemData {...props}></ItemData> },
  { key: "tax", component: (props) => <Tax {...props}></Tax> },
  { key: "category", component: (props) => <Category {...props}></Category> },
  { key: "charge", component: (props) => <Charge {...props}></Charge> },
  {
    key: "paymentMethods",
    component: (props) => <PaymentMethods {...props}></PaymentMethods>,
  },
  {
    key: "apExpense",
    component: (props) => <APExpense {...props}></APExpense>,
  },
  { key: "vanSale", component: (props) => <VanSale {...props}></VanSale> },
];

const RenderedComponents = AppBarComponents.map((component) => {
  if (
    userEmailAddress === "sonicsync@ioi-tech.com" &&
    component.key !== "vanSale"
  ) {
    return null;
  }
  return component.component;
}).filter((component) => component !== null);

let AppBarLabels = [];
if (userEmailAddress === "sonicsync@ioi-tech.com") {
  AppBarLabels = [{ label: "Van Sales Configuration" }];
} else {
  AppBarLabels = [
    { label: "Branch" },
    { label: "Warehouses" },
    { label: "Customer" },
    { label: "Vendor" },
    { label: "Item master data" },
    { label: "Tax" },
    { label: "Category" },
    { label: "Charge" },
    { label: "PaymentMethods" },
    { label: "AP Expense" },
    { label: "Van Sales Configuration" },
  ];
}

const ActionButtons = [
  {
    key: "checkup",
    label: "Check up",
    icon: "done_all",
    toolTip: "Check up all settings",
  },
  {
    key: "updateInfo",
    label: "Update info",
    icon: "call_made",
    toolTip: "Synchronize all updates",
  },
];

export { AppBarLabels, RenderedComponents, ActionButtons };
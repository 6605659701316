import { withStyles } from '@material-ui/core';
import { Description } from '@material-ui/icons';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import GeneralForm from '../Partials/GeneralForm'
import styles from './styles';
import Table from '~/Components/EnhancedTable';
import { DataToColumns } from "~/Services/Helpers/Normalizers"
import { columnsOptions } from './data';

class ViewItTravel extends React.Component {
    view = "show";
    code = this.props.match?.params?.id ? this.props.match.params.id : '';
    loading = false;
    data = {
        General: {}
    }
    headerData = {};
    tableData = [];
    columns = [];
    componentDidMount() {
        if (!!this.code) {
            this.loading = true;
            this.props.entityStore.get({ code: this.code })
        }
    }
    setView = (view) => {
        this.view = view;
    }

    PasswordFix = (password) => {
        return !!password ? password.length * "*" : "No record"
    }
    arrayFix = (array) => {
        return array.length > 0 ? array.join(" , ") : "No record"
    }
    arrayObjFix = (array, name) => {

        let element = [];
        for (let index = 0; index < array.length; index++) {

            element.push(array[index][name])

        }
        return element
    }
    decamelize(str, separator) {
        separator = typeof separator === 'undefined' ? ' ' : separator;
        return str
            .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
            .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
            .toLowerCase();
    }
    dataTOShowView = (object, options = {}) => {
        let data = {}
        Object.keys(object).map((key) => {
            if (options[key]) {
                data[`${this.decamelize(key)} : `] = options[key](object[key])
            } else data[`${this.decamelize(key)} : `] = object[key]
        })
        return data
    }
    entityDidReceived(data) {
        if (!!data) {
            this.loading = false;
            const { itemCode, itemName, foreignName, itemsGroupCode, customsGroupCode, salesVATGroup,
                barCode, vatLiable
                , purchaseItem, incomeAccount, exemptIncomeAccount
                , expanseAccount, mainsupplier, supplierCatalogNo, picture,
                user_Text, serialNum, commissionPercent, commissionSum, commissionGroup, treeType, assetItem,
                dataExportCode, manufacturer, quantityOnStock, quantityOrderedFromVendors,
                quantityOrderedByCustomers, manageSerialNumbers, manageBatchNumbers, valid,
                validFrom, validTo, validRemarks, frozen, frozenFrom, frozenTo,

                inventoryItem, desiredInventory, minInventory, maxInventory, inventoryUOM,
                inventoryWeight, inventoryWeightUnit1, inventoryWeight1, inventoryWeightUnit,
                inventoryNumber, inventoryUoMEntry,

                purchaseUnit, purchaseItemsPerUnit,
                purchasePackagingUnit, purchaseQtyPerPackUnit, purchaseUnitLength, purchaseLengthUnit,
                purchaseUnitWidth, purchaseWidthUnit, purchaseUnitHeight, purchaseHeightUnit,
                purchaseUnitVolume, purchaseVolumeUnit, purchaseUnitWeight, purchaseWeightUnit,
                purchaseVATGroup,
                purchaseFactor1,
                purchaseFactor2, purchaseFactor3, purchaseFactor, purchaseHeightUnit1,
                purchaseUnitHeight1, purchaseLengthUnit1, purchaseUnitLength1, purchaseWeightUnit1,
                purchaseUnitWeight1, purchaseWidthUnit1, purchaseUnitWidth1,


                salesFactor3, salesFactor1, salesFactor2, salesFactor4, salesHeightUnit, salesUnitHeight,
                salesLengthUnit, salesUnitLength, salesWeightUnit, salesUnitWeight, salesWidthUnit,
                salesUnitWidth, defaultSalesUoMEntry, salesItem, salesItemsPerUnit, salesPackagingUnit,
                salesQtyPerPackUnit, salesUnitLength1, salesLengthUnit1,
                salesUnitWidth1, salesWidthUnit1, salesUnitHeight1, salesHeightUnit1, salesUnitVolume,
                salesVolumeUnit, salesUnitWeight1, salesWeightUnit1, salesUnit,

                movingAveragePrice, foreignRevenuesAccount,
                ecRevenuesAccount, foreignExpensesAccount, ecExpensesAccount, avgStdPrice, defaultWarehouse,
                shipType, glMethod, taxType, manageStockByWarehouse, forceSelectionOfSerialNumber,
                manageSerialNumbersOnReleaseOnly, wtLiable,
                costAccountingMethod, sww, warrantyTemplate, indirectTax, arTaxCode, apTaxCode,
                baseUnitName, itemCountryOrg, issueMethod, sriAndBatchManageMethod, isPhantom,
                procurementMethod, componentWarehouse, orderIntervals,
                orderMultiple, minOrderQuantity, itemType, itemClass, outgoingServiceCode,
                incomingServiceCode, serviceGroup, ncmCode, materialType, materialGroup, productSource,

                planningSystem, leadTime,

                properties1,
                properties2, properties3, properties4, properties5, properties6, properties7, properties8,
                properties9, properties10, properties11, properties12, properties13, properties14, properties15, properties16, properties17,
                properties18, properties19, properties20, properties21, properties22, properties23, properties24,
                properties25, properties26, properties27, properties28, properties29, properties30, properties31,
                properties32, properties33, properties34, properties35, properties36, properties37, properties38,
                properties39, properties40, properties41, properties42, properties43, properties44, properties45,
                properties46, properties47, properties48, properties49, properties50, properties51, properties52,
                properties53, properties54, properties55, properties56, properties57, properties58, properties59,
                properties60, properties61, properties62, properties63, properties64,

                autoCreateSerialNumbersOnRelease, dnfEntry, gtsItemSpec, gtsItemTaxCategory, fuelID, beverageTableCode,
                beverageGroupCode, beverageCommercialBrandCode, series, toleranceDays, typeOfAdvancedRules,
                issuePrimarilyBy, noDiscounts, assetClass, assetGroup, technician, employee, location,
                assetStatus, capitalizationDate, statisticalAsset, cession, deactivateAfterUsefulLife, manageByQuantity,
                uoMGroupEntry,
                defaultPurchasingUoMEntry, depreciationGroup,
                assetSerialNumber,
                defaultCountingUnit, countingItemsPerUnit, defaultCountingUoMEntry, excisable, chapterID, scsCode,
                spProdType, prodStdCost, inCostRollup, virtualAssetItem, enforceAssetSerialNumbers, attachmentEntry,
                linkedResource, updateDate, updateTime, gstRelevnt, sacEntry, gstTaxCategory, serviceCategoryEntry,
                capitalGoodsOnHoldPercent, capitalGoodsOnHoldLimit, assessableValue, assVal, soiExcisable, tnved,
                importedItem, pricingUnit, createDate, createTime, itemPrices, itemWarehouseInfoCollection,
                itemPreferredVendors, itemLocalizationInfos, itemProjects, itemDistributionRules, itemAttributeGroups,
                itemDepreciationParameters, itemPeriodControls, itemUnitOfMeasurementCollection, itemBarCodeCollection,
                itemIntrastatExtension, vatGroup, businessPartner, u_Fo_Id, u_Fo_Types, u_Fo_Combo_Id, u_Fo_Combo_Name,
                u_Fo_Combo_Group_Id, u_Fo_Combo_Group_Name, u_Fo_Combo_Size_Id, u_Fo_Combo_Size_Name
            } = data;

            const Properties = {
                properties1,
                properties2, properties3, properties4, properties5, properties6, properties7, properties8,
                properties9, properties10, properties11, properties12, properties13, properties14, properties15,
                properties16, properties17, properties18, properties19, properties20, properties21, properties22,
                properties23, properties24, properties25, properties26, properties27, properties28, properties29,
                properties30, properties31, properties32, properties33, properties34, properties35, properties36,
                properties37, properties38, properties39, properties40, properties41, properties42, properties43,
                properties44, properties45, properties46, properties47, properties48, properties49, properties50,
                properties51, properties52, properties53, properties54, properties55, properties56, properties57,
                properties58, properties59, properties60, properties61, properties62, properties63, properties64,
            };

            const Purchasing = {
                purchaseUnit, purchaseItemsPerUnit,
                purchasePackagingUnit, purchaseQtyPerPackUnit, purchaseUnitLength, purchaseLengthUnit,
                purchaseUnitWidth, purchaseWidthUnit, purchaseUnitHeight, purchaseHeightUnit,
                purchaseUnitVolume, purchaseVolumeUnit, purchaseUnitWeight, purchaseWeightUnit,
                purchaseVATGroup,
                purchaseFactor1,
                purchaseFactor2, purchaseFactor3, purchaseFactor, purchaseHeightUnit1,
                purchaseUnitHeight1, purchaseLengthUnit1, purchaseUnitLength1, purchaseWeightUnit1,
                purchaseUnitWeight1, purchaseWidthUnit1, purchaseUnitWidth1,
            };

            const SalesData = {
                salesFactor3, salesFactor1, salesFactor2, salesFactor4, salesHeightUnit, salesUnitHeight,
                salesLengthUnit, salesUnitLength, salesWeightUnit, salesUnitWeight, salesWidthUnit,
                salesUnitWidth, defaultSalesUoMEntry, salesItem, salesItemsPerUnit, salesPackagingUnit,
                salesQtyPerPackUnit, salesUnitLength1, salesLengthUnit1,
                salesUnitWidth1, salesWidthUnit1, salesUnitHeight1, salesHeightUnit1, salesUnitVolume,
                salesVolumeUnit, salesUnitWeight1, salesWeightUnit1, salesUnit,
            };

            const planningData = { leadTime };

            const General = { manufacturer, linkedResource };

            const InventoryData = {
                inventoryItem, desiredInventory, minInventory, maxInventory, inventoryUOM,
                inventoryWeight, inventoryWeightUnit1, inventoryWeight1, inventoryWeightUnit,
                inventoryNumber, inventoryUoMEntry,
            };

            this.data = {
                General: this.dataTOShowView(General),
                "Purchasing Data": this.dataTOShowView(Purchasing, {}),
                "Sales Data": this.dataTOShowView(SalesData, {}),
                "Inventory Data": this.dataTOShowView(InventoryData, {}),
                "planning Data": this.dataTOShowView(planningData, {}),
                "Properties": this.dataTOShowView(Properties, {}),
            }
            this.headerData = {
                "item No": itemCode,
                "foreignName": foreignName,
                "Item Type": itemType,
                "Items Group": itemsGroupCode,
                "item Prices": this.arrayFix(this.arrayObjFix(itemPrices, "price")),
                "Bar Code": barCode,
            }

        }
    }
    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }

        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <>
                        <ShowView title="Item Master Data. "
                            data={this.headerData}
                        // setView={this.setView}
                        />

                        <ShowView title=""
                            Tab={true}
                            data={this.data}
                        // setView={this.setView}
                        />
                        <Table
                            showKeyIndex={0}
                            title="item Depreciation Parameters"
                            defaultData={this.tableData}
                            columns={this.columns}

                        />
                    </>
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <GeneralForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewItTravel, {
    view: observable,
})




export default withStyles(styles)(asEntity({ storeId: "ItemsDataForm" })(observer(ViewItTravel)));

import React from 'react';
import AuthScreen from '../../../Partials/AuthScreen';
import LoginForm from './LoginForm'
import { LoginPageData, formProps } from './data';

const Login = (props) => {
    // const classes = useStyles();
    return (
        <AuthScreen {...LoginPageData}>
            <LoginForm {...props} {...formProps} />
        </AuthScreen>
    )
}


export default Login;


import React from 'react';
import useStyles from './styles'
import { SettingsOutlined } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'

function EmptyPage(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <SettingsOutlined style={{ fontSize: 70 }} />
            <h2> <FormattedMessage id="EmptyPage" />  </h2>
        </div>
    );
}


export default EmptyPage;

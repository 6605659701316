import { autoGeneratedColumnsEnumNormalizer , autoGeneratedColumnsObjectNormalizer } from "~/Services/Helpers";

const boolObject = {
    0: 'No',
    1: 'Yes'
}


export const columnsValuesNormalizer = {
    vatLiable: autoGeneratedColumnsEnumNormalizer(boolObject),
    purchaseItem: autoGeneratedColumnsEnumNormalizer(boolObject),
    salesItem: autoGeneratedColumnsEnumNormalizer(boolObject),
    inventoryItem: autoGeneratedColumnsEnumNormalizer(boolObject),
    itemGroups : autoGeneratedColumnsObjectNormalizer('groupName'),
    customsGroup : autoGeneratedColumnsObjectNormalizer('name'),
}
import { Button, CircularProgress, withStyles } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { decorate, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Input } from '~/Components/From';
import { asEntity, withFormik } from '~/Hoc';
import { UserContext } from '~/Services/Providers';
import { formData } from './data';
import useStyles from "./styles";

class LoginForm extends React.Component {

    static contextType = UserContext;

    loading = false;
    errors = {};


    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.props.validateForm();
        validate.then((err) => {
            if (this.props.isValid) {
                this.props.entityStore.post(this.props.values);
                this.loading = true;
            }
        });
    };

    entityDidPosted(data) {
        if (data) {
            localStorage.setItem("emailID",data.user.email);
            this.loading = false;
            const { setUserContext } = this.context;
            setUserContext({ data: data?.user })
            this.props.moveForward()
        }
    }

    entityDidCatch(error) {
        this.loading = false;
        this.props.notify("error", "Email/password may be incorrect")
    }


    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <form noValidate onSubmit={this.handleSubmit}  >
                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                        {
                            formData.map(field => (
                                <Input type={field.type}
                                    name={field.name}
                                    labelWidth={field.labelWidth}
                                    placeholder={<FormattedMessage id={`Auth.LoginForm.${field.name}`} />}
                                    icon={field.icon}
                                    InputChange={this.props.handleChange}
                                    id={`login-form-${field.name}`}
                                    key={`login-form-${field.name}`}
                                    error={this.props.errors[field.name]}
                                    errorText={this.props.errors[field.name]}
                                />
                            ))
                        }
                        <div style={{ flexBasis: "100%", textAlign: "center" }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.SubmitButton}
                                endIcon={this.loading ? <CircularProgress color="light" size={15} /> : <ArrowForward />}
                                type="submit"
                                disabled={this.loading}

                            >
                                <FormattedMessage id="Auth.Continue" />

                            </Button>
                        </div>

                        <Link to="/administration/user/user-profile" />
                    </div>
                </form>
            </div>

        )
    }
}

decorate(LoginForm, {
    loading: observable,
    errors: observable
})

export default withStyles(useStyles)(withFormik(asEntity({ storeId: "login" })(observer(LoginForm))));

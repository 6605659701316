import { withStyles } from '@material-ui/core';
import { decorate, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Loading from '~/Components/Loading';
import ShowView from '~/Components/ShowView';
import { asEntity } from '~/Hoc';
import AutoSyncForm from '../Partials/AutoSyncForm';
import styles from './styles';
import { NotificationContext } from '../../../../Services/Providers';

class ViewAutoSync extends React.Component {
    static contextType = NotificationContext;
   
    view = "show";
    data = {};

    id = this.props.match?.params?.id ? this.props.match.params.id : '';
    
    loading = false;

    componentDidMount() {
        this.getData();
    }

    getData() {
        this.loading = true;
        this.props.entityStore.get()
    }

    setView = (view) => {
        this.view = view;
        if (view === "show") {
          this.getData();
        }
      }
      

    entityDidReceived(data) {
        if (!!data) {
            this.data = data;
            this.loading = false;
        }
    }

    entityDidCatch({ error, id }) {
        this.notify("error", error);
        this.data = [];
        this.loading = false;
    }
    notify = (severity, message) => {
        const { setNotification } = this.context;
        setNotification((currState) => ({
            ...currState,
            open: true,
            severity,
            message
        }))
    }

    render() {
        const { classes } = this.props;

        if (this.loading) {
            return <Loading />
        }
        return (
            <div className={classes.root}>
                {
                    this.view === "show" &&
                    <ShowView title="Auto Sync Info."
                        showViewName="AutoSync"
                        data={toJS(this.data)}
                        setView={this.setView}
                        // shouldValueCheck={false}
                    />
                }
                {
                    this.view === "edit" &&
                    <div className={classes.Edit}>
                        <AutoSyncForm {...this.props} setView={this.setView} />
                    </div>
                }
            </div>
        )
    }
}

decorate(ViewAutoSync, {
    view: observable,
})




export default withStyles(styles)(asEntity({ storeId: "BrowseAutoSync" })(observer(ViewAutoSync)));
